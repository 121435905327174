// const TRACKING_COOKIE_NAME = 'lst__';

import { decodeUrlParams } from './urlUtils';

const sectorRe1 = /[/-]s-([0-9]{1,6})-([a-z0-9]+)/;
const sectorRe2 = /[/-]s-([0-9]{1,6})/;

export function sectorFromUrl(url: string): string | undefined {
	const m1 = url.match(sectorRe1);
	if (m1) {
		return m1[1];
	}

	const m2 = url.match(sectorRe2);
	if (m2) {
		return m2[1];
	}

	return undefined;
}

const roleRe1 = /[/-]s-([0-9]{1,6})-([a-z0-9]+)/;
const roleRe2 = /[/-]r-([a-z0-9]+)/;

export function roleFromUrl(url: string): string | undefined {
	const m1 = url.match(roleRe1);
	if (m1) {
		return m1[2];
	}

	const m2 = url.match(roleRe2);
	if (m2) {
		return m2[1];
	}

	return undefined;
}

export function paramsFromQueryString(search: string): Record<string, string> {
	const requestParams = decodeUrlParams(search);
	const ret: Record<string, string> = {};

	for (const k in requestParams) {
		const v = requestParams[k];
		if (
			k.startsWith('utm') ||
			k === 'gclid' ||
			k === 'fbclid' ||
			k === 'ttclid' ||
			k === 'role' ||
			k === 'sector' ||
			k === 'test' ||
			k === 'variant'
		) {
			ret[k] = v;
		}
	}

	return ret;
}

export function paramsFromPath(url: string): Record<string, string> {
	const ret: Record<string, string> = {};

	const role = roleFromUrl(url);
	if (role) {
		ret['role'] = role;
	}

	const sector = sectorFromUrl(url);
	if (sector) {
		ret['sector'] = sector;
	}

	return ret;
}

function getCookieByName(doc: Document, cookieName: string): string | undefined {
	let ret: string | undefined = undefined;
	doc.cookie.split(';').forEach(function (el) {
		const [key, value] = el.split('=');
		if (key.trim() === cookieName) {
			ret = decodeURIComponent(value);
		}
	});
	return ret;
}

export function metaTagsFromDOM(doc: Document): Record<string, string> {
	const ret: Record<string, string> = {};

	const metaTags = doc.querySelectorAll('meta');
	for (const mt of metaTags) {
		const name = mt.getAttribute('name');
		const content = mt.getAttribute('content');
		if (name && content) {
			ret[name] = content;
		}
	}

	return ret;
}

export function paramsFromDOM(doc: Document): Record<string, string> {
	const metaTags = metaTagsFromDOM(doc);
	const ret = paramsFromMetaValues(metaTags);

	if (doc.referrer) {
		ret['referrer'] = doc.referrer;
	}

	// we need the hubspot user token for submitting a form from the backend later...
	const hubspotutk = getCookieByName(doc, 'hubspotutk');
	if (hubspotutk) {
		ret['hubspotutk'] = hubspotutk;
	}

	return ret;
}

export function paramsFromMetaValues(metaTags: Record<string, string>): Record<string, string> {
	const ret: Record<string, string> = {};

	for (const k of Object.keys(metaTags)) {
		const v = metaTags[k];

		if (k.startsWith('lingio-')) {
			ret[k.substring(7)] = v;
		}
	}

	return ret;
}

export function paramsFromLocation(location: Location): Record<string, string> {
	const fromPath = paramsFromPath(location.pathname);
	const fromQS = paramsFromQueryString(location.search);

	const ret: Record<string, string> = {
		...fromPath,
		...fromQS,
		href: location.href
	};
	ret['href'] = location.href;

	return ret;
}
