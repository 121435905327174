import { paramsFromDOM, paramsFromLocation } from './extract';
import { combinePageParameters, incrementInvocations, mergeParameters } from './parameters';
import { decodeUrlParams, encodeUrlParams } from './urlUtils';

const TRACKING_COOKIE_NAME = 'lst__';
const GOOGLE_MEASUREMENT_ID = 'G-RNX8YW4ZE7';

async function paramsFromGoogleAnalytics(): Promise<Record<string, string>> {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	if (!window || typeof window['gtag'] !== 'function') {
		return {};
	}

	const ret: Record<string, string> = {};

	return new Promise((resolve) => {
		try {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window['gtag']('get', GOOGLE_MEASUREMENT_ID, 'client_id', (client_id) => {
				ret['gtag_clientid'] = client_id;
				resolve(ret);
			});
		} catch (e) {
			console.warn('failed to call gtag');
		}

		setTimeout(() => {
			resolve(ret);
		}, 1000);
	});
}

export async function reportRegistrationEvent() {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	if (!window || typeof window['gtag'] !== 'function') {
		return;
	}

	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window['gtag']('event', 'PlgRegistrationCreated', {});
	} catch (e) {
		console.warn('failed to call gtag');
	}
}

export async function trackRequestAdParameters(doNotIncrement = false): Promise<string> {
	const locationParams = paramsFromLocation(location);
	const domParams = paramsFromDOM(document);
	const extraParams = await paramsFromGoogleAnalytics();
	const requestParams = combinePageParameters(locationParams, domParams, extraParams);

	let params = decodeUrlParams(sessionStorage.getItem(TRACKING_COOKIE_NAME) ?? '');
	params = mergeParameters(params, requestParams);

	if (!doNotIncrement) {
		params = incrementInvocations(params);
	}

	const encoded = encodeUrlParams(params);
	sessionStorage.setItem(TRACKING_COOKIE_NAME, encoded);

	return encoded;
}

export async function createTrackingString(): Promise<string> {
	const encoded = await trackRequestAdParameters(true);
	// const params = decodeUrlParams(sessionStorage.getItem(TRACKING_COOKIE_NAME) ?? '');
	// const encoded = encodeUrlParams(params);
	return encoded;
}
