export function combinePageParameters(
	locationParameters: Record<string, string>,
	domParameters: Record<string, string>,
	extraParameters: Record<string, string>
): Record<string, string> {
	return {
		...domParameters,
		...(extraParameters ?? {}),
		...locationParameters
	};
}

export function mergeParameters(
	previousParams: Record<string, string>,
	requestParams: Record<string, string>
): Record<string, string> {
	const params = { ...previousParams };

	for (const key of Object.keys(requestParams)) {
		const value = requestParams[key];

		if (value) {
			// Some keys are not to be overwritten and are handled separately
			if (key === 'role' || key === 'sector') {
				if (!params[`landing${key}`]) {
					params[`landing${key}`] = value;
				}
				params[`${key}`] = value;
			} else if (key === 'href') {
				if (!params['srchref']) {
					params['srchref'] = value;
				}
				params[`${key}`] = value;
			} else if (key === 'referrer') {
				if (!params[key]) {
					params['referrer'] = value;
				}
			} else {
				// Others are ok to overwrite with latest value
				params[key] = value;
			}
		}
	}

	return params;
}

export function incrementInvocations(
	previousParams: Record<string, string>
): Record<string, string> {
	const params = { ...previousParams };
	params['invocations'] = (~~(params['invocations'] ?? '0') + 1).toString();
	return params;
}
