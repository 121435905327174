export function decodeUrlParams(params: string): Record<string, string> {
	const usp = new URLSearchParams(params);
	const ret: Record<string, string> = {};
	for (const k of usp.keys()) {
		ret[k] = usp.get(k) as string;
	}
	return ret;
}

export function encodeUrlParams(params: Record<string, string>): string {
	const usp = new URLSearchParams();
	for (const k of Object.keys(params)) {
		usp.set(k, params[k]);
	}
	return usp.toString();
}
